import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SoftBackgroundImage } from './SoftImage';

export class ProjectEntry extends Component {
    constructor(props) {
        super(props);
        this.project = props.project;
        this.displayAs = props.displayAs;
    }

    makeImagePath(project) {
        return require("../images/Projects/" + project.directory_path + "/" + project.halftone_image);
    }

    render() {
        switch (this.displayAs) {
            case "work":
                this.baseClassName = "bright_base";
                this.projectText = (
                    <div className="project_text">
                        <h4>{this.project.title}</h4>
                        <h5>{this.project.subtitle}</h5>
                    </div>
                );
                break;
            case "next":
                this.baseClassName = "dark_base";
                this.projectText = (
                    <div className="project_text">
                        <p className="p1">Next project:</p>
                        <br />
                        <p className="p1">{this.project.title}</p>
                        <p className="p3">{this.project.subtitle}</p>
                    </div>
                );
                break;
            default:
                break;
        }
        return (
            <div className={this.baseClassName + " work_item"} key={this.project.directory_path}>

                <Link to={"/projects/" + this.project.directory_path}>
                    <div className="project_entry">

                        <SoftBackgroundImage className="project_image_container" src={this.makeImagePath(this.project)} >
                        </SoftBackgroundImage>

                        <div className="project_image_container project_entry_overlay">
                        </div>

                        { this.projectText }

                    </div>
                </Link>

            </div>
        )
    }
}
