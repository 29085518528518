import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import TopBar from './menus/TopBar'
import Home from './views/Home'
import About from './views/About'
import Project from './views/Project'

function getPathParts(location) {
    let pathArr = (location || {}).pathname.split("/");
    pathArr = pathArr.filter(n => n !== "");
    return pathArr;
}

class Routers extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            previousParts: getPathParts(props.location)
        };
    }

    static getDerivedStateFromProps(props, state) {
        return{ previousParts: getPathParts(props.location) };
    }

    getClassName(previousPathParts, newPathParts) {
        if (previousPathParts.length > 0 && previousPathParts[previousPathParts.length - 1] === "about") {
            return "bottomToTop";
        }

        if (newPathParts.length > 0 && newPathParts[newPathParts.length - 1] === "about") {
            return "topToBottom";
        }

        if (newPathParts.length > previousPathParts || newPathParts[0] === "projects") {
            return "leftToRight";
        }

        return "rightToLeft";
    }

    render() {
        return (
            <Route
                render={({ location }) => {
                    return (
                        <TransitionGroup component={null}>
                            <CSSTransition
                                key={location.pathname}
                                timeout={800}
                                classNames="pageSlider"
                                mountOnEnter={false}
                                unmountOnExit={true}
                            >

                                <div className={"full_height " + this.getClassName(this.state.previousParts, getPathParts(location))}>

                                    <Switch location={location}>
                                        <Route exact path="/" component={Home} />
                                        <Route path="/projects/:projectName" component={Project} />
                                        <Route path="/about" component={About} />
                                    </Switch>

                                </div>

                            </CSSTransition>

                        </TransitionGroup>
                    );
                }}
            />
        );
    }
}

const WrapRouters = withRouter(Routers);

class App extends Component {

    render() {

        const SiteContainer = ({ links, location }) => (

            <div className="container full_height">
                <Route render={({ location }) => {

                    return (<WrapRouters />)
                }} />

            </div>
        )

        return (
            <BrowserRouter>

                <div className="app full_height">

                    <TopBar></TopBar>

                    <Switch>
                        <Route component={SiteContainer} />
                    </Switch>

                </div>

            </BrowserRouter>
        )
    }
}

export default App;
