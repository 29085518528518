import React, { Component } from 'react';
import { imageCached, preloadImage } from '../components/ImageCache'

function getOpacityClassName(visible){
    return visible ? "fullOpacity " : "zeroOpacity ";
}

export class SoftImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.state.visible || this.props.src == null){
            return;
        }

        let showImage = function(){
            this._isMounted && this.setState({visible: true});
        }.bind(this);

        if (imageCached(this.props.src)){
            showImage();
        }else{
            this._isMounted && preloadImage(this.props.src, showImage);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
     }

    render(){
        let extraClassName = this.props.className ? this.props.className : "";
        return (
            <img 
                className={getOpacityClassName(this.state.visible) + extraClassName} 
                width={this.props.width} 
                height={this.props.height} 
                src={this.props.src} 
                alt={this.props.alt} />
        )
    }
}

export class SoftBackgroundImage extends SoftImage {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    render(){
        return (
            <div 
                className={getOpacityClassName(this.state.visible, this.props.almostFullOpacity) + this.props.className} 
                id={this.props.id} 
                style={{ backgroundImage : "url(" + this.props.src + ")" }}>

                {this.props.children}

            </div>
        )
    }
}