const Cache = {};

export function allImagesCached(srcArray){
    for(let i=0; i < srcArray.length; i++){
        if (!imageCached(srcArray[i])){
            return false;
        }
    }

    return true;
}

export function imageCached(src){
    return Cache.hasOwnProperty(src);
}

export function preloadImages (srcArray, callback) {
    let loadedImagesArray = [], loadedImages = 0;

    function imageLoadPost(src){
        Cache[src] = true;
        loadedImages++;
        if (loadedImages === srcArray.length){
            callback();
        }
    }

    for (let i = 0; i < srcArray.length; i++){
        let currentSrc = srcArray[i];
        loadedImagesArray[i] = preloadImage(currentSrc, () => imageLoadPost(currentSrc));
    };
}

export function preloadImage (src, callback) {
    let loadedImage = new Image();
    loadedImage.src = src;
    loadedImage.onload = callback;
    loadedImage.onerror = callback;
    return loadedImage;
}