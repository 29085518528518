import React, { Component } from 'react';
import projectsJson from '../projects.json';
import { NavLink } from 'react-router-dom';
import Unity, { UnityContent } from "react-unity-webgl";
import { SoftImage } from '../components/SoftImage';
import { ProjectEntry } from '../components/ProjectEntry';


class Project extends Component {

    constructor(props) {
        super(props);
        this.projectIndex = this.getProjectIndex(props.match.params.projectName);
        this.project = this.projectIndex > -1 ? this.allProjects[this.projectIndex] : {};
        this.nextProject = this.getProjectWithIndex(this.projectIndex + 1);
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    allProjects = projectsJson;

    getProjectIndex(projectName) {
        return this.allProjects.findIndex(element => { return element.directory_path === projectName });
    }

    getProjectWithIndex(fromHere) {
        return this.allProjects[fromHere % this.allProjects.length];
    }

    textToHtml(text) {
        return { __html: text };
    }

    render() {
        var game;
        if (this.project.game != null) {
            const unityContent = new UnityContent(
                process.env.PUBLIC_URL + "/" + this.project.directory_path + "/Build/WebGLGame.json",
                process.env.PUBLIC_URL + "/" + this.project.directory_path + "/Build/UnityLoader.js"
            );

            game = <Unity unityContent={unityContent} />;
        }

        var gameHowToPlay;
        if (this.project.game_how_to_play != null) {
            gameHowToPlay = process.env.PUBLIC_URL + "/" + this.project.directory_path + "/how_to_play.png";
        }

        return (
            <div className="project-page-flex-box">
                
                <NavLink className="back-flex-box center" strict exact to="/">
                    <img src={require('../images/back-arrow.png')} />
                    <i class="arrow left"></i>
                </NavLink>

                <div className="project-content-flex-box">

                    <div className="center">
                        <SoftImage
                            alt={this.project.title}
                            className="project-cover"
                            src={require("../images/Projects/" + this.project.directory_path + "/" + this.project.thumbnail_image)} />
                    </div>

                    <div className="project-title">
                        <p className="p1">{this.project.title}</p>
                        <br />
                        <p className="p3">{this.project.subtitle}</p>
                    </div>
                
                    <div className="bio">
                        {this.project.description ?
                            <div className="left project-text">
                                <p className="p2 p_text" dangerouslySetInnerHTML={this.textToHtml(this.project.description)}></p>
                            </div> : null}

                        <br />

                        {this.project.videos.map(video =>
                            <video className="project-video left" controls key={video}>
                                <source src={require("../images/Projects/" + this.project.directory_path + "/Videos/" + video)} type="video/mp4" />
                                Your browser does not support HTML5 videos :(
                            </video>
                        )}

                        {this.project.album.length > 0 ?
                            <div className="left">
                                {
                                    this.project.album.map((image, index) => 
                                        <img
                                            className="album_image" 
                                            key={index} 
                                            src={require("../images/Projects/" + this.project.directory_path + "/Album/" + image)}
                                            alt={this.project.title}>
                                        </img>)
                                }
                            </div>

                            : null}

                        {gameHowToPlay ?
                            <div className="center">
                                <br />
                                <p>How to play:</p>
                                <br />
                                <div><img alt="How to play" width="200px" src={gameHowToPlay} /></div>
                                <br />
                                <p>Turn on sound for full experience.</p>
                                <br />
                            </div>

                            : null}

                        {this.project.game ? game : null}

                        {this.project.additional_info ? <div><p2 className="p_text" dangerouslySetInnerHTML={this.textToHtml(this.project.additional_info)}></p2></div> : null}
                        

                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>

                    <ProjectEntry project={ this.nextProject } displayAs="next"></ProjectEntry>

                </div>
            </div>

        );
    }
}

export default Project
