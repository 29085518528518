import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';


class About extends Component {
    render() {
        return (
            <div className="content full_height">
                <div className="bio flex-box full_height">
                    <div className="left-column-flex-box">
                        <p className="p1">Hi! I'm a multidisciplinary visual designer based in London. Having studied MA Graphic Media Design in UK, and BComm in Law & Business in Hong Kong, I have developed a strong passion for tackling complex issues through visual creation and design research. My work focuses on the use of typography and multimedia to formulate meaningful stories, and my practice ranges between digital, brand development, editorial design and 3D design.
                        </p>
                        <br />
                        <p className="p1">Being a co-founder of Mirador Collective, a collaborative space that explores interactive media and virtual worlds, I also focus my practice on the application of critical design methods and creative technology in social context. I believe that design has the ability to invite critical discussions and public engagement on solving social issues.
                        </p>
                        <br />
                        <br />
                        <p className="p3">Get in touch</p>
                        <p className="p2">lawsuiki@gmail.com</p>
                        </div>

                    <div className="right-column-flex-box">
                        <hr /><p className="p1 page-title">Awards, exhibitions, talks and project features</p><hr />
                        <br />
                        <p className="p3">Aesthetica Short Film Festival 2020</p>
                        <p className="p2">an international film festival celebrating independent film from around the world</p>
                        <br />
                        <p className="p3">ACIIID</p>
                        <p className="p2">feature on the virtual design gallery</p>
                        <br />
                        <p className="p3">Cyberia - New Media Playground Exhibition</p>
                        <p className="p2">an immersive exhibition organised by TIFA Working Studio</p>
                        <br />
                        <p className="p3">Capital</p>
                        <p className="p2">a performance & experience on Gentrification at the Barbican</p>
                        <br />
                        <p className="p3">Flux Social</p>
                        <p className="p2">a talk on Mirador Collective’s interactive projects</p>
                        <br />
                        <p className="p3">Present Future – Outstanding Entries</p>
                        <p className="p2">Art & Technology Star Award competition by MANA</p>
                        <br />
                        <p className="p3">A Line Which Forms A Volume</p>
                        <p className="p2">a symposium organized by London College of Communication on design-led research</p>
                        <br />
                        <p className="p3">East Asian Ticket Club</p>
                        <p className="p2">a talk on Mirador Collective’s interactive projects</p>
                        <br />
                    </div>
                    <div className="cross-column-flex-box">
                        <NavLink strict exact to="/">
                            <img alt="exit" src={require('../images/cross-05.png')} />
                        </NavLink>
                    </div>
                </div>
                <br />
                <br />
                <br />

            </div>
        );
    }
}

export default About
