import React from 'react';
import projectsJson from '../projects.json';
import { scrollToId } from '../functions/scroll'
import { ProjectEntry } from '../components/ProjectEntry'

class Work extends React.Component {

    render() {
        return (
            <div>
                <div className="selected_projects" id="projects">
                    <p className="p1">Selected Projects</p>
                </div>
                <div className="fill-width" >{projectsJson.map(project =>
                    <ProjectEntry key={project.directory_path} project={project} displayAs="work" ></ProjectEntry>
                )}
                </div>

                <div className="bottom-bar">
                    <div className="Get_in_touch">
                        <p className="p3">Get in touch</p>
                        <p className="p2">lawsuiki@gmail.com</p>
            
                    </div>

                    <div className="Back-to-top-arrow">
                        <div onClick={(e) => {
                            e.preventDefault();
                            const target = "projects";
                            scrollToId("#" + target);
                        }}> 
                            <img alt="Back to top" src={require('../images/up-arrow.png')} />
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default Work
