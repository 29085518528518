import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TopBar extends Component {

    transformMenu() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="top-bar">
                <div className="page-logo">
                    <Link to=""><h1>Suki Law</h1></Link>
                </div>

                <div className="top-menu">
                    <Link to="/about"><p className="p1">About me</p></Link>
                </div>
            </div>
        )
    }
}

export default TopBar
