import React, { Component } from 'react';
import Work from './Work';
import EffectCanvas from '../components/water_effect/EffectCanvas';
import { scrollToId } from '../functions/scroll'

class Home extends Component {

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        var hash = window.location.hash;
        if (window.location.hash !== "") {
            scrollToId(hash, 800);
        }
    }

    render() {
        return (
            <div>

                <div className="content fill-page fill-width home_background">

                    <EffectCanvas></EffectCanvas>

                    <div className="horizontal-menu">
                        
                        <h3><a onClick={(e) => {
                            e.preventDefault();
                            const target = "projects";
                            scrollToId("#" + target);
                        }}>Explore projects<br></br><br></br><img src={require('../images/down-arrow-2.png')} /></a>
                        </h3>
                    </div>

                </div>

                <Work></Work>

            </div>

        );
    }
}

export default Home
